$(function () {

	$('.nav_prev.disabled').on('click', function (e) {
		e.preventDefault();
	});

	CardFilter.init();


	ScrollFix();

	$(document)
		// Задать вопрос
		.on('click', '.js-question-this-car', function (e) {
			showAddQuestFrom();
			e.preventDefault();
		})
		// Забронировать авто
		.on('click', '.js-order-this-car', function (e) {
			showReserveCarFrom();
			e.preventDefault();
		})
		// Фрма кредита
		.on('click', '.open_credit_form', function (e) {
			showCreditWindow(
				$(this).data('credit_type'),
				$(this).data('price'),
				$(this).data('brand'),
				$(this).data('model'),
				$(this).data('salon')
			);
			e.preventDefault();
		});

	$(document).on('click', '.close_form', function () {
		$('.fw_win_close_button').click();
	});

	$(document).on('click', '.open_security_policy_form', function(e) {
		createPopUp(
			'Политика безопасности',
			'<p>Личные данные будут обрабатываться на сервере сайта с ограниченным доступом к ним, не будут продаваться и предоставляться другим лицам и учреждениям, если не будет на то разрешения пользователя сайта или если этого не требует действующее законодательство. Личные данные хранятся и обрабатываются в рамках разрешения, данного пользователем, и положениями российского права, в частности Федерального закона «О персональных данных» от 27.07.2006 №152-ФЗ.</p>',
			false
		);
		e.preventDefault();
	});
	$(document).on('click', '.i_agree_personal_data', function(e) {
		createPopUp(
			'Согласие на обратную коммуникацию',
			'<p>Настоящим даю согласие на обработку моих вышеуказанных персональных данных Операторам: ООО "МЭЙДЖОР ХОЛДИНГ" (143420, Московская область, Красногвардейский район, п/о Архангельское, 25 км а/м "Балтия", складской комплекс "Кулон Балтия")</p>'
				+ '<p>с целями: учета предоставленной информации в базах данных; проведения статистических исследований, а также исследований, направленных на улучшение качества продукции и услуг; проведения маркетинговых программ, в том числе для продвижения товаров, работ, услуг на рынке; информирования меня о новых товарах и услугах Оператора и партнеров Операторов (например, посредством отправки журналов, отправки приглашений на презентации продуктов, сообщений о технических нововведениях, предстоящих работах по сервисному обслуживанию, условиях покупки нового автомобиля и т.д.) с помощью различных средств связи, а именно посредством:</p>'
				+ '<p>интернет; сообщений на адрес электронной почты; коротких текстовых сообщений (SMS) и мультимедийных сообщений (MMS) на номер телефона; а также посредством использования информационно-коммуникационных сервисов, таких как Viber, WhatsApp и тому подобных; телефонных звонков.</p>'
				+ '<p>Я разрешаю совершать со своими персональными данными следующие действия: сбор, систематизацию, накопление, хранение (в электронном виде и на бумажном носителе), уточнение (обновление, изменение), использование, распространение (в том числе передачу) моих персональных данных третьим лицам, с которыми у Операторов имеются действующие договоры, в рамках которых Операторы поручают обработку персональных данных в вышеуказанных целях, включая трансграничную передачу персональных данных, обезличивание, блокирование, уничтожение, с использованием средств автоматизации и без использования таких средств.</p>'
			,
			false
		);
		e.preventDefault();
	});
	$(document).on('click', '.hpromise-js-ajax-content', function(e) {
		createPopUp(
			'Программа H-Promise',
			$('.hpromise_content').html(),
			false,
			'hpromise_pop'
		);
		e.preventDefault();
	});


	$(document).on('success.formjor', '.major_form', function () {
		$(this).parents('.fw_win_content_container').find('.title_to_hidden').html('');
	})


	$(document).on('click', '.overlay_box_title', function () {
		var nextHidddenBox = $(this).parents('.row').next('.overlay_box');
		if($(this).hasClass('view')) {
			nextHidddenBox.removeClass('view');
			$(this).removeClass('view');
		} else {
			nextHidddenBox.addClass('view');
			$(this).addClass('view');
		}
	})


});



var CardFilter = function () {
	var that = {
		init: function () {
			$('.sort_block a').click(function (e) {
				var searchStr = window.location.search;
				$.each(['price', 'run', 'year'], function( i, name ) {
					$.each(['asc', 'desc'], function( i, value ) {
						searchStr = searchStr.replace('&' + name + '=' + value, '');
						searchStr = searchStr.replace(name + '=' + value, '');
					});
				});


				window.location =
					$(this).data('base-url')
					+ searchStr
					+ (searchStr ? '&' : '?')
					+ $(this).data('name') + '=' + $(this).data('value');

				e.preventDefault();
			});
		}
	};

	return that;
}();





// Аякс-форма добавления вопроса
function showAddQuestFrom() {
	createPopUpForm('Задать вопрос', '/ajax/forms/getAddQuestionForm/');
}
function showReserveCarFrom() {
	createPopUpForm('Забронировать', '/ajax/forms/showReserveCarFrom/');
}
function showCreditWindow(creditType, price, brand, model, salon) {
	createPopUpForm('Кредитный калькулятор', '/ajax/forms/creditUsedForm/', {
		credit_type: creditType,
		price: price,
		brand: brand,
		model: model,
		salon: salon
	}, function (data) {
		initCreditForm($('.fw_win_content_container'), false, data.credit_params.type,  data.credit_params.price, data.brand, data.model);
	});
}



// Всплывающее окно
function createPopUpForm(title, url, post_data, done_function, add_class) {
	if(!post_data)
		post_data = {};
	if(!add_class)
		add_class = '';

	var win = createPopUp(title, '', true, add_class);
	fw_MOJAX.send({
		url : url,
		data: post_data,
		to : win.content,
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: win.content,
		user_success: function (data) {
			if(data && data.major_form_replace_values)
				majorFormReplaceValues( $(win.content).find('.major_form'), data.major_form_replace_values );

			majorFormReplaceValues($(win.content).find('.major_form'), [
				{name: 'referral_url', value: window.location}
			]);

			// INIT.fullForm(win.content);
			if(done_function)
				done_function(data);



		}
	});
}

// Автозамена значений в оригинальной форме .major_form
function majorFormReplaceValues(form, values) {
	if(values) {
		$.each(values, function (i, item) {
			$(form).find('*[name="' + item.name + '"]').val(item.value);
		});
	}
}

function createPopUp(title, content, lock_page, add_class) {
	return fw_WIN.create({
		offset: 0,
		title: '<div class="title_to_hidden">' + title + '</div>',
		container_class: "form_window " + add_class,
		content_class: "fw_win_form_content",
		overlay_class: "form_overlay",
		content: content,
		lock_page: lock_page
	});
}


function initEvaluationOfCarInPage() {
	var wrapper = $( '.form_load_wrapper');
	fw_MOJAX.send({
		url : '/ajax/forms/showEvaluationOfCar/',
		data: {},
		to : wrapper,
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: wrapper,
		user_success: function (data) {
			majorFormReplaceValues(wrapper.find('.major_form'), [
				{name: 'referral_url', value: window.location}
			]);

			getSelectOptions(
				wrapper.find('select[name="brand"]'),
				'/ajax/forms/getBrandsListToSelect/',
				{}
			);

			drawCustomFormItem(wrapper);
		}
	});


	$(document).on('change', 'select[name="brand"]', function () {

		getSelectOptions(
			wrapper.find('select[name="model"]'),
			'/ajax/forms/getModelsListToSelect/',
			{
				brand: $(this).val()
			}
		);
	});
}

function getSelectOptions(selectEl, url, data) {
	fw_MOJAX.send({
		url : url,
		data: data,
		to : selectEl,
		overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
		overlay_to: selectEl,
		user_success: function (data) {
			var pWrapper = $(selectEl).parents('.input-layout.select');
			pWrapper.find('.drop-it-down-custom').remove();
			$(selectEl).clone().insertAfter(selectEl);
			$(selectEl).remove();

			drawCustomFormItem(pWrapper);
		}
	});
}

function initCreditForm(container, creditParams, creditType, price) {
	// INIT.initFormElements();
	container.find(".submit_button").click(function () {
		$(this).closest("form").submit();
	});
	container.find("form").on("success.formjor", function () {
		// unlockWindowByForm(this);
		// $("html, body").scrollTo(container.parent(), 300, {offset: {top: -60}});
	});
	var creditForm = new CreditForm(creditType, price);
	creditForm.init();
}


function startScrollToSearch() {
	if(window.location.search) {
		$(window).scrollTop( $('.autos_search_result_title').offset().top - 15 );
	}
}

function ScrollFix () {
	$(document).ready(function () {
		if ($(document).height() > $(window).height()) {
			if (!$('body').hasClass('body_scroll_fix')) {
				$('body').addClass('body_scroll_fix')
			}
			fw_MAMMON.fixBodyScroll.setNewFix();
		}
	});
	$(window).resize(function () {
		if ($(document).height() > $(window).height()) {
			if (!$('body').hasClass('body_scroll_fix')) {
				$('body').addClass('body_scroll_fix')
			}
			fw_MAMMON.fixBodyScroll.setNewFix();
		}
		else {
			$('body').removeClass('body_scroll_fix');
			$('body').css('width', 'auto');
		}
	});

}